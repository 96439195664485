<template>
  <v-card
    :max-width="getCardWidth"
    :max-height="getCardHeight"
    color="secondary"
    @click="addUserPoint"
  >
    <v-img
      :src="getUserImage"
      :height="getImageHeight"
      cover
      class="text-white"
    >
      <v-card-title v-if="displaySize !== 'xs'" align="left">
        {{ viewModel.firstName }}
      </v-card-title>
      <v-card-text v-if="displaySize === 'xs'" align="left" class="px-1 py-1">
        {{ viewModel.firstName }}
      </v-card-text>
    </v-img>
    <v-card-title align="center" class="py-0">
      {{ displaySize !== 'xs' ? 'Internet Points:' : '' }}
      {{ viewModel.internetPoints }}
    </v-card-title>
  </v-card>
</template>

<script setup>
import { useStore } from '@/store'
import { defineProps, computed } from 'vue'
import { useDisplay } from 'vuetify'

const display = useDisplay()
const store = useStore()
const props = defineProps({
  userProfile: Object,
})
const displaySize = computed(() => display.name.value)
const viewModel = computed(() => props.userProfile)
const user = computed(() => store.user)

function addUserPoint() {
  store.addApiUserPoint(viewModel.value, user.value.userId)
}

const getCardWidth = computed(() => {
  if (displaySize.value === 'xs') return '150px'
  return '300px'
})

const getCardHeight = computed(() => {
  if (displaySize.value === 'xs') return '300px'
  else return null
})

const getImageHeight = computed(() => {
  if (displaySize.value === 'xs') return '80px'
  return '200px'
})

const getUserImage = computed(() => {
  if (viewModel.value.showHappyImage) {
    return viewModel.value.happySelfieImage
  } else if (viewModel.value.showMadImage) {
    return viewModel.value.madSelfieImage
  } else {
    return viewModel.value.normalSelfieImage
  }
})
</script>
