export function authentication() {
  function login() {
    window.location.href = '/.auth/login/aad'
  }

  function logout() {
    window.location.href = '/.auth/logout'
  }

  async function isAuthenticated() {
    const response = await fetch('/.auth/me')
    const payload = await response.json()
    const { clientPrincipal } = payload
    return !!clientPrincipal
  }

  return { login, logout, isAuthenticated }
}
