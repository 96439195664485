import { defineStore } from 'pinia'
import axios from 'axios'
import { ref } from 'vue'

export const useStore = defineStore('main', () => {
  const user = ref({
    claims: [],
    userId: '',
    userDetails: '',
    userRoles: [],
    identityProvider: '',
  })
  const userProfile = ref({
    firstName: '',
    lastName: '',
    selfieImage: '',
    internetPoints: 0,
  })
  const allUsers = ref([])
  const loading = ref(false)
  const displayConfetti = ref(false)

  const setUser = async () => {
    const response = await fetch('/.auth/me')
    const payload = await response.json()
    const { clientPrincipal } = payload
    user.value = clientPrincipal
  }

  const setUserProfile = (value) => {
    userProfile.value = value
  }

  const setAllUsers = (value) => {
    allUsers.value = value
  }

  const getApiUser = async () => {
    loading.value = true
    try {
      const response = await axios.get('/api/GetUser/' + user.value.userId)
      setUserProfile(response.data)
      loading.value = false
      return response.data
    } catch (error) {
      loading.value = false
      return false
    }
  }

  const getAllApiUsers = async () => {
    loading.value = true
    try {
      const response = await axios.get('/api/GetAllUsers')
      setAllUsers(response.data)
    } catch (error) {
      console.log('Unable to get all users.')
    }
    loading.value = false
  }

  const postApiUser = async (userProfile) => {
    loading.value = true
    try {
      await axios.post('/api/PostUser/' + user.value.userId, userProfile, {
        headers: {
          'Content-Type': 'application/json',
          'Cache-Control': 'no-cache',
        },
      })
      await getApiUser()
    } catch {
      console.error('Unable to update user profile.')
    }
    loading.value = false
  }

  const addApiUserPoint = async (userProfile, userGiving) => {
    try {
      await axios.post(
        `/api/AddUserPoint?id=${userProfile.id}&idGiving=${userGiving}`
      )
    } catch {
      console.error('Unable to update user profile.')
    }
  }

  const updateUserPoints = async (updateUser) => {
    let index = allUsers.value.findIndex(
      (user) => user.id === updateUser.UserId
    )
    allUsers.value[index].internetPoints += updateUser.Points
    allUsers.value[index].showHappyImage = true
    setTimeout(() => {
      allUsers.value[index].showHappyImage = false
    }, 4000)
    if (updateUser.UserId === user.value.userId) {
      displayConfetti.value = true
      setTimeout(() => {
        displayConfetti.value = false
      }, 1000)
    }
  }

  const updateUserMadAt = async (updateUser) => {
    for (let i = 0; i < allUsers.value.length; i++) {
      if (allUsers.value[i].id !== updateUser) {
        allUsers.value[i].showMadImage = true
      }
    }
    setTimeout(() => {
      for (let i = 0; i < allUsers.value.length; i++) {
        allUsers.value[i].showMadImage = false
      }
    }, 4000)
  }

  return {
    loading,
    user,
    userProfile,
    allUsers,
    setUser,
    getApiUser,
    postApiUser,
    addApiUserPoint,
    getAllApiUsers,
    updateUserPoints,
    displayConfetti,
    updateUserMadAt,
  }
})
