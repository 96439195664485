<template>
  <v-app>
    <v-main>
      <HomePage />
    </v-main>
  </v-app>
</template>

<script setup>
import { onBeforeMount } from 'vue'
import { HubConnectionBuilder } from '@aspnet/signalr'
import { useStore } from '@/store'
import HomePage from '@/views/HomePage.vue'

const store = useStore()

onBeforeMount(() => {
  let connection = new HubConnectionBuilder().withUrl('/api').build()

  connection.start().catch((err) => {
    return console.error(err.toString())
  })
  connection.on('notify', (message) => {
    store.updateUserPoints(message)
  })
  connection.on('madAtUser', (message) => {
    store.updateUserMadAt(message)
  })
})
</script>
