<template>
  <app-bar
    :authenticated="authenticated"
    @login="handleLogin"
    @logout="handleLogout"
    @modify-user="handleModifyUser"
    @show-leaderboard="handleShowLeaderboard"
  ></app-bar>
  <div v-if="loading">
    <div class="text-center">Loading Llama says be patient!</div>
    <v-img src="@/assets/106772-dancing-llama.gif"></v-img>
  </div>
  <v-container v-if="!loading">
    <v-row>
      <v-col
        v-for="(user, index) in allApiUsers"
        :key="index"
        align="center"
        cols="4"
        lg="3"
        xl="2"
        sm="4"
      >
        <UserCard :user-profile="user"></UserCard>
      </v-col>
    </v-row>
  </v-container>
  <LoginDialog
    :login-dialog="loginDialog"
    @set-login-dialog="handleSetLoginDialog"
  ></LoginDialog>
  <UserDialog
    :user-dialog="userDialog"
    :modify="modify"
    @set-user-dialog="handleSetUserDialog"
  ></UserDialog>
  <LeaderboardDialog
    :leaderboard-dialog="showLeaderboard"
    @set-leaderboard-dialog="handleSetLeaderBoardDialog"
  ></LeaderboardDialog>
</template>

<script setup>
import AppBar from '@/components/AppBar.vue'
import LoginDialog from '@/components/LoginDialog.vue'
import UserDialog from '@/components/UserDialog.vue'
import LeaderboardDialog from '@/components/LeaderboardDialog.vue'
import UserCard from '@/components/UserCard.vue'
import { ref, onMounted, computed, watch } from 'vue'
import { authentication } from '@/composables/authentication'
import { useStore } from '@/store'
import { startConfetti, stopConfetti } from '@/plugins/confetti'

const store = useStore()
const authenticated = ref(false)
const apiUser = ref(null)
const allApiUsers = computed(() => store.allUsers)
const { logout, isAuthenticated } = authentication()
const displayConfetti = computed(() => store.displayConfetti)
const modify = ref(false)
const showLeaderboard = ref(false)

function handleLogin() {
  loginDialog.value = true
}

function handleLogout() {
  logout()
}

function handleModifyUser() {
  modify.value = true
  userDialog.value = true
}

function handleShowLeaderboard() {
  showLeaderboard.value = true
}

function handleSetLeaderBoardDialog(value) {
  showLeaderboard.value = value
}

const loginDialog = ref(false)
function handleSetLoginDialog(value) {
  loginDialog.value = value
}

const userDialog = ref(false)
function handleSetUserDialog(value) {
  userDialog.value = value
}

const loading = computed(() => store.loading)

onMounted(async () => {
  authenticated.value = await isAuthenticated()

  if (authenticated.value) {
    await store.setUser()
    apiUser.value = await store.getApiUser()
    if (!apiUser.value) {
      userDialog.value = true
    } else {
      await store.getAllApiUsers()
    }
  }
})

watch(displayConfetti, (value) => {
  if (value) {
    startConfetti()
  }
  setTimeout(() => {
    stopConfetti()
  }, 1000)
})
</script>

<style>
.confetti {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
</style>
