<template>
  <v-dialog v-model="showDialog" max-width="600px" persistent>
    <v-card>
      <v-card-title>Tell us about yourself!</v-card-title>
      <v-card-text>
        Hi RIPA user! We need a bit of additional information before you can
        start collecting your incredibly valuable INTERNET POINTS!
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="userProfile.firstName"
                label="Display Name"
                variant="underlined"
                :rules="nameRules"
                validate-on-blur
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn color="primary" @click="handleTakePicture('normal')"
                >Take Normal Selfie</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-avatar size="100" class="ml-6">
                <v-img :src="normalSelfie" contain></v-img>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn color="primary" @click="handleTakePicture('happy')"
                >Take Happy Selfie</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-avatar size="100" class="ml-6">
                <v-img :src="happySelfie" contain></v-img>
              </v-avatar>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6">
              <v-btn color="primary" @click="handleTakePicture('mad')"
                >Take Mad Selfie</v-btn
              >
            </v-col>
            <v-col cols="12" sm="6">
              <v-avatar size="100" class="ml-6">
                <v-img :src="madSelfie" contain></v-img>
              </v-avatar>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="handleSaveUserProfile">Save</v-btn>
        <v-btn v-if="props.modify" @click="showDialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <input
    id="camera-input"
    type="file"
    accept="image/*"
    capture="user"
    hidden
    @change="handleShowPicture"
  />
  <SelfieDialog
    :title="title"
    :selfie-dialog="selfieDialog"
    :url="url"
    @on-save-selfie="handleSaveSelfie"
  ></SelfieDialog>
</template>

<script setup>
import SelfieDialog from '@/components/SelfieDialog.vue'
import { defineProps, defineEmits, computed, reactive, ref } from 'vue'
import { useStore } from '@/store'

const title = ref('')
const normalSelfie = ref('')
const happySelfie = ref('')
const madSelfie = ref('')
const url = ref('')
const store = useStore()
const props = defineProps({
  userDialog: Boolean,
  modify: Boolean,
})
const emit = defineEmits(['setUserDialog'])
const userProfile = reactive({
  firstName: '',
  lastName: '',
  normalSelfie: '',
  happySelfie: '',
  madSelfie: '',
})

const showDialog = computed({
  get: () => props.userDialog,
  set: (value) => {
    emit('setUserDialog', value)
  },
})

const nameRules = [
  (v) => !!v || 'Display name is required',
  (v) =>
    (v && v.length <= 40) || 'Display name must be less than 40 characters',
]

function handleSaveUserProfile() {
  store.postApiUser(userProfile)
  showDialog.value = false
}

let selfieDialog = ref(false)

function handleShowPicture(e) {
  const image = e.target?.files[0]
  const reader = new FileReader()
  reader.readAsDataURL(image)
  reader.onload = () => {
    url.value = URL.createObjectURL(image)
  }
  selfieDialog.value = true
}

function handleTakePicture(value) {
  title.value = value
  const cameraInput = document.getElementById('camera-input')
  cameraInput?.click()
}

function handleSaveSelfie(selfie, type) {
  switch (type) {
    case 'normal':
      normalSelfie.value = selfie.toDataURL()
      userProfile.normalSelfie = normalSelfie.value
      break
    case 'happy':
      happySelfie.value = selfie.toDataURL()
      userProfile.happySelfie = happySelfie.value
      break
    case 'mad':
      madSelfie.value = selfie.toDataURL()
      userProfile.madSelfie = madSelfie.value
      break
  }
  selfieDialog.value = false
}
</script>
