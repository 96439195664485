<template>
  <v-dialog v-model="showDialog" max-width="500px">
    <v-card>
      <v-card-title class="headline">RIPA Login</v-card-title>
      <v-card-text>
        <v-container fluid>
          <img
            src="/img/ms-symbollockup_signin_light.svg"
            class="login-hover"
            @click="login"
          />
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="showDialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { authentication } from '@/composables/authentication'
import { defineProps, defineEmits, computed } from 'vue'

const props = defineProps({
  loginDialog: Boolean,
})
const emit = defineEmits(['setLoginDialog'])
const { login } = authentication()

const showDialog = computed({
  get: () => props.loginDialog,
  set: (value) => {
    emit('setLoginDialog', value)
  },
})
</script>

<style>
.login-hover {
  cursor: pointer;
}
</style>
