<template>
  <v-app-bar flat>
    <v-toolbar-title>Rollie Internet Points Applier</v-toolbar-title>
    <v-icon icon="mdi-trophy" class="mr-2" @click="onShowLeaderboard"></v-icon>
    <v-btn v-if="isAuthenticated" fab icon>
      <v-avatar>
        <v-img :src="userProfile.normalSelfieImage"></v-img>
      </v-avatar>
      <v-menu activator="parent">
        <v-list>
          <v-list-item>
            <v-list-item-title>
              <v-btn flat @click="modifyUser">Modify User</v-btn>
            </v-list-item-title>
          </v-list-item>
          <v-list-item>
            <v-list-item-title>
              <v-btn flat @click="logout">Logout</v-btn>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-btn>

    <v-tooltip v-if="!isAuthenticated" location="bottom">
      <template #activator="{ tooltip }">
        <v-btn v-bind="tooltip" icon @click="login">
          <v-icon>mdi-login-variant</v-icon>
        </v-btn>
      </template>
      <span>Login</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script setup>
import { useStore } from '@/store'
import { defineEmits, defineProps, computed } from 'vue'

const store = useStore()
const emit = defineEmits(['login', 'logout', 'modify-user', 'show-leaderboard'])
const props = defineProps({
  authenticated: Boolean,
})
const isAuthenticated = computed(() => props.authenticated)
const userProfile = computed(() => store.userProfile)

function modifyUser() {
  emit('modify-user')
}

function login() {
  emit('login')
}

function logout() {
  emit('logout')
}

function onShowLeaderboard() {
  emit('show-leaderboard')
}
</script>
