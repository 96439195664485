<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <v-card>
      <v-card-title>Crop your {{ title }} selfie! </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <cropper
                ref="cropperComponent"
                class="cropper"
                :src="url"
                :stencil-component="CircleStencil"
                @change="onChange"
              ></cropper>
            </v-col>
            <v-col>
              <preview
                :image="result.image"
                :coordinates="result.coordinates"
                class="preview"
              ></preview>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-btn @click="handleSaveSelfie">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { Cropper, Preview, CircleStencil } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css'
import { computed, defineProps, defineEmits, ref } from 'vue'

const props = defineProps({
  title: String,
  selfieDialog: Boolean,
  url: String,
})
const emit = defineEmits(['set-selfie-dialog', 'on-save-selfie'])

let cropperComponent = ref()
let result = ref({
  coordinates: null,
  image: {
    src: '',
    width: 0,
    height: 0,
  },
})

const showDialog = computed({
  get: () => props.selfieDialog,
  set: (value) => {
    emit('set-selfie-dialog', value)
  },
})

function onChange({ coordinates, image }) {
  result.value = {
    coordinates,
    image,
  }
}

function handleSaveSelfie() {
  const { canvas } = cropperComponent.value.getResult()
  emit('on-save-selfie', canvas, props.title)
}
</script>

<style>
.cropper {
  height: 200px;
  width: 200px;
  margin: auto;
}
.preview {
  border-radius: 50%;
  height: 200px;
  width: 200px;
  margin: auto;
}
</style>
