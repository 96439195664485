<template>
  <v-dialog v-model="showDialog" max-width="600px">
    <v-card>
      <v-card-title>Leaderboard</v-card-title>
      <v-card-text>Current Month Top 3</v-card-text>
      <v-container fluid>
        <v-row v-for="(user, index) in currentLeaders" :key="index">
          <v-col>
            <v-chip color="primary"
              ><v-icon
                start
                :icon="`mdi-numeric-${index + 1}-circle-outline`"
              ></v-icon>
              {{ currentLeaders[index].firstName }} with
              {{ currentLeaders[index].internetPoints }} Internet Points</v-chip
            >
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-card-text>Last Month</v-card-text>
      <v-divider></v-divider>
      <v-card-text>Year</v-card-text>
      <v-card-actions>
        <v-btn @click="showDialog = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { useStore } from '@/store'

const store = useStore()
const emit = defineEmits(['set-leaderboard-dialog'])
const props = defineProps({
  leaderboardDialog: Boolean,
})

const showDialog = computed({
  get: () => props.leaderboardDialog,
  set: (value) => {
    emit('set-leaderboard-dialog', value)
  },
})

const currentLeaders = computed(() => {
  let allUsers = [...store.allUsers]
  allUsers.sort((a, b) => {
    return b.internetPoints - a.internetPoints
  })
  return allUsers.splice(0, 3)
})
</script>
